import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

import { Auth0Config } from "@authorization";

import {
    ApiConfig,
    AppConfiguration,
    ApplicationInsightsConfig,
    Environment,
    MatomoConfig,
    MetadataOverridesConfig,
    MrdmAuthApiConfig,
    Tenant,
    UserflowConfig
} from "./app-configuration";

@Injectable({ providedIn: "root" })
export class AppConfigurationService {
    private _configuration = new BehaviorSubject<AppConfiguration | null>(null);
    readonly configuration$ = this._configuration.asObservable();

    getTenant(): Tenant {
        return this._getConfiguration()?.tenant ?? "dica";
    }

    getEnvironment(): Environment {
        return this._getConfiguration()?.environment ?? "";
    }

    getInstance(): string {
        return this._getConfiguration()?.instance ?? "";
    }

    getAuth0Config(): Auth0Config {
        return (
            this._getConfiguration()?.auth0 ?? {
                domain: "",
                clientId: "",
                audience: "",
                scope: ""
            }
        );
    }

    async getAuth0ConfigAsync(): Promise<Auth0Config> {
        const configuration = await this.configuration$.toPromise();

        return (
            configuration?.auth0 ?? {
                domain: "",
                clientId: "",
                audience: "",
                scope: ""
            }
        );
    }

    getApiConfig(): ApiConfig {
        return (
            this._getConfiguration()?.api ?? {
                fhirMiddlewareServiceUrl: ""
            }
        );
    }

    getMrdmAuthApiConfig(): MrdmAuthApiConfig {
        return (
            this._getConfiguration()?.mrdmAuthApi ?? {
                url: ""
            }
        );
    }

    getMatomoConfig(): MatomoConfig {
        return this._getConfiguration()?.matomo ?? { siteId: 0, positionDimensionId: 0 };
    }

    getApplicationInsightsConfig(): ApplicationInsightsConfig {
        return (
            this._getConfiguration()?.applicationInsights ?? {
                instrumentationKey: "",
                correlationHeaderExcludedDomains: []
            }
        );
    }

    getMetadaOverridesConfig(): MetadataOverridesConfig | undefined {
        return this._getConfiguration()?.metadataOverrides;
    }

    getUserflowConfig(): UserflowConfig {
        return (
            this._getConfiguration()?.userflow ?? {
                environmentKey: "",
                contentId: ""
            }
        );
    }

    getDoNotTrack(): boolean {
        return window.location.hostname === "localhost";
    }

    getSurveyUrl(): string {
        return this._getConfiguration()?.surveyUrl ?? "";
    }

    redirectToSurvey(): boolean {
        return this._getConfiguration()?.surveyRedirect ?? false;
    }

    setConfiguration(configuration: AppConfiguration): void {
        this._configuration.next(configuration);
        this._configuration.complete();
    }

    private _getConfiguration(): AppConfiguration | null {
        return this._configuration.getValue();
    }
}
