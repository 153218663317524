import { AppProjectsService } from "@app/services/app-projects.service";
import { FhirEntityListGroup } from "./entity-list-group";
import { DataEntryUIControlType, FormConfiguration } from "@fhir-api";
import { FhirEntity } from "./entity";
import { Scm } from "@logex/expression-validator";
import { FhirEntityList } from "./entity-list";

interface DatasetConstructorOptions {
    organizationUri?: string;
    organizationId?: number;
}

export class FhirDataset {
    private _product: string | null = null;
    private _url = "";

    get name(): string {
        return this._name;
    }

    get type(): string {
        return "dataset";
    }

    get label(): string {
        return "dhba 2023";
    }

    get description(): string {
        return "";
    }

    get defaultLanguage(): string | null {
        return null;
    }

    get rootDataset(): FhirEntityList | null {
        return this.getDataset(this.rootDatasetName) ?? null; // hardcoded for now, no other way to do it
    }

    get rootDatasetName(): string {
        return "patient_dcra_2024";
    }

    get datasets(): FhirEntity[] {
        return this._entities;
    }

    get url(): string {
        return this._url;
    }

    get defaultControl(): DataEntryUIControlType {
        return DataEntryUIControlType.Text;
    }

    get skipEmptyCreationDialog(): boolean {
        return false;
    }

    get hideSubjectCountInSubjectList(): boolean {
        return false;
    }

    get scm(): Scm {
        return this._scm;
    }

    get formConfiguration(): FormConfiguration | undefined {
        return this._formConfiguration;
    }

    get organizationId(): string | null {
        return this._organizationId;
    }

    private _entities: FhirEntity[] = [];
    private _entitiesLookup: Record<string, FhirEntity> = {};
    private _organizationId: string | null = null;

    constructor(
        private _name: string,
        private _projectsService: AppProjectsService,
        private _scm: Scm,
        private _formConfiguration?: FormConfiguration,
        { organizationUri, organizationId }: DatasetConstructorOptions = {}
    ) {
        this._connectEntities();
        this._setProduct();

        if (organizationUri !== undefined) {
            this._organizationId = organizationId?.toString() ?? null;
            this._url = `/registry/${organizationUri}/${this.name}`;
        }
    }

    getDataset(name: string): FhirEntityList | null {
        return this._entitiesLookup[name]?.parentEntityList ?? null;
    }

    getProduct(): string | null {
        return this._product;
    }

    private _connectEntities(): void {
        for (let index in this._scm?.childContract) {
            const entityListGroup = new FhirEntityListGroup(
                this,
                this._scm.childContract[index],
                this._formConfiguration
            );
            entityListGroup.entityLists
                .map(entityList => entityList.entity)
                .flat(1)
                .forEach(entity => {
                    this._entities.push(entity);
                    this._entitiesLookup[entity.name] = entity;
                });
        }
    }

    private _setProduct(): void {
        const project = this._projectsService.getProjectByUri(this.name);
        if (project?.properties.product === undefined) return;

        this._product = project.properties.product.replace("Product.", "");
    }
}
