import {
    DataEntryUIControlType,
    FormConfigurationAttribute,
    FormConfigurationOptionsDirection
} from "@fhir-api";
import { FHIRSubjectVariable } from "./subject-variable";
import { DatePipe } from "@angular/common";
import decodeHtmlEntities from "@app/util/html-util";
import {
    AttributeDataTypeEnum,
    isEnumScmAttribute,
    ScmAttachedExtension,
    ScmEntityAttribute,
    ValidationError
} from "@logex/expression-validator";
import { FhirEntity } from "./entity";

export interface IconResource {
    disabled?: boolean;
    src: string;
    tooltip: string;
}

export interface OptionImageDefinition {
    src: string;
    title?: string | null;
    titleColor?: string | null;
    dotColor: string | null;
    width: number | null;
    height: number | null;
}

export interface FhirOptionset {
    options: FhirAttributeOption[];
}

export interface FhirAttributeOption {
    value: string;
    label: string;
    description?: string;
    disabled: boolean;
    image?: OptionImageDefinition;
}

export const DEFAULT_BOOLEAN_OPTIONS: FhirAttributeOption[] = [
    {
        value: "true",
        label: "Ja",
        description: "Ja",
        disabled: false
    },
    {
        value: "false",
        label: "Nee",
        description: "Nee",
        disabled: false
    }
];

export class FhirAttribute {
    get type(): AttributeDataTypeEnum {
        return this._scm.dataType;
    }

    get name(): string {
        return this._scm.name;
    }

    get directPii(): boolean {
        return this._scm.directPii ?? false;
    }

    get label(): string {
        return this._formConfiguration?.label || this._scm.name;
    }

    get visible(): boolean {
        return true;
    }

    get included(): boolean {
        return this._formConfiguration?.include === false ? false : true;
    }

    get inputControl(): DataEntryUIControlType {
        return this._formConfiguration?.inputControl || this._inferInputControl(this._scm.dataType);
    }

    get scm(): ScmEntityAttribute {
        return this._scm;
    }

    get formConfiguration(): FormConfigurationAttribute | undefined {
        return this._formConfiguration;
    }

    get isExternalOptionset(): boolean {
        return false;
    }

    get readonly(): boolean {
        return this._formConfiguration?.readonly || false;
    }

    get defaultValue(): string {
        return this._formConfiguration?.defaultValue || "";
    }

    get required(): boolean {
        return this._scm.required ?? false;
    }

    get format(): string | null {
        return null;
    }

    get help(): string {
        return this._formConfiguration?.help || "";
    }

    get length(): number {
        return (
            this.scm?.stringExtension?.maxLength ??
            this.scm?.rangeExtension?.maxValue?.toString()?.length ??
            20
        );
    }

    get controlVariant(): FormConfigurationOptionsDirection {
        return (
            this._formConfiguration?.optionsDirection ?? FormConfigurationOptionsDirection.Vertical
        );
    }

    get resourceIcon(): IconResource | undefined {
        return undefined;
    }

    get entity(): FhirEntity {
        return this._parentEntity;
    }

    get forceOptionsetValue(): boolean {
        return true;
    }

    get optionset(): ScmAttachedExtension | undefined {
        if (!isEnumScmAttribute(this._scm)) return undefined;
        const enumName = this._scm.referenceExtension?.enumName;
        return this._scmAttachedExtensions.find(extension => extension.tableName === enumName);
    }

    get hasOptions(): boolean {
        return this.optionset?.enumOptions !== undefined && this.optionset?.enumOptions?.length > 0;
    }

    get isBarcodeScannerInput(): boolean {
        return false;
    }

    private _options: FhirAttributeOption[] = [];

    private _datePipe: DatePipe = new DatePipe("nl-NL");

    constructor(
        private _parentEntity: FhirEntity,
        private _scm: ScmEntityAttribute,
        private _scmAttachedExtensions: ScmAttachedExtension[],
        private _formConfiguration?: FormConfigurationAttribute
    ) {
        this._setOptions();
    }

    getUIControlType(defaultControl: DataEntryUIControlType): DataEntryUIControlType {
        return this.inputControl || defaultControl;
    }

    getValueLabel(subjectVariable?: FHIRSubjectVariable | null): string {
        if (this.hasOptions) {
            const option = this._options.find(o => o.value === subjectVariable?.value);
            return option?.label ?? "";
        }
        return this.getFormattedValue(subjectVariable?.value ?? "");
    }

    getValidations(subjectVariable?: FHIRSubjectVariable | null): ValidationError[] | null {
        if (!subjectVariable) return null;
        return subjectVariable.validations?.length ? subjectVariable.validations : null;
    }

    getHiddenOptions(subjectVariable?: FHIRSubjectVariable | null): Set<string> | null {
        if (!subjectVariable) return null;

        if (subjectVariable.hiddenOptions.length === 0) return null;

        return new Set(subjectVariable.hiddenOptions);
    }

    getOptions(subjectVariable?: FHIRSubjectVariable | null): FhirAttributeOption[] {
        if (!subjectVariable) return this._options; // hardcoded for now, because the visibility is not yet implemented

        const hidden = this.getHiddenOptions(subjectVariable);
        if (hidden == null) return this._options;

        return this._options.filter(option => !hidden.has(option.value));
    }

    getOptionImage(subjectVariable?: FHIRSubjectVariable | null): OptionImageDefinition | null {
        if (!subjectVariable) return null;

        const options = this.getOptions(subjectVariable);
        if (!options) return null;

        const option = options.find(o => o.value === subjectVariable.value);
        return option?.image ?? null;
    }

    getFormattedValue(value: string): string {
        if (this.type === AttributeDataTypeEnum.Date) {
            console.log("this._datePipe", value);
            return value ? (this._datePipe.transform(new Date(value), "dd-MM-yyyy") ?? "") : "";
        }
        if (this.type === AttributeDataTypeEnum.DateTime) {
            return value
                ? (this._datePipe.transform(new Date(value), "dd-MM-yyyy HH:mm") ?? "")
                : "";
        }
        if (this.type === AttributeDataTypeEnum.Timestamp) {
            console.log(value);
            const date_value = isNaN(parseInt(value)) ? parseInt(value) : value;
            return date_value
                ? (this._datePipe.transform(new Date(date_value), "dd-MM-yyyy HH:mm") ?? "")
                : "";
        }
        if (this.type === AttributeDataTypeEnum.Time) {
            return value ? (this._datePipe.transform(new Date(value), "HH:mm") ?? "") : "";
        }
        return value;
    }

    private _inferInputControl(dataType: AttributeDataTypeEnum): DataEntryUIControlType {
        switch (dataType) {
            case AttributeDataTypeEnum.Boolean:
                return DataEntryUIControlType.Radio;
            case AttributeDataTypeEnum.Date:
                return DataEntryUIControlType.Date;
            case AttributeDataTypeEnum.DateTime:
            case AttributeDataTypeEnum.Timestamp:
                return DataEntryUIControlType.DateTime;
            case AttributeDataTypeEnum.Enum:
                const optionsCount = this.optionset?.enumOptions?.length || 0;
                if (optionsCount <= 3) {
                    return DataEntryUIControlType.Radio;
                } else if (optionsCount < 6) {
                    return DataEntryUIControlType.Select;
                } else {
                    return DataEntryUIControlType.Autocomplete;
                }
            case AttributeDataTypeEnum.String:
                return DataEntryUIControlType.Text;
            default:
                return DataEntryUIControlType.Text;
        }
    }

    private _setOptions(): void {
        if (isEnumScmAttribute(this._scm)) {
            this._options =
                this.optionset?.enumOptions?.map(option => {
                    const variableOption: FhirAttributeOption = {
                        value: option[0],
                        label: decodeHtmlEntities(option[1]) ?? "",
                        description: decodeHtmlEntities(option[1]) ?? "",
                        disabled: false // hardcoded to false, because the visibility is not yet implemented
                    };

                    return variableOption;
                }) || [];
        }
        if (this._options.length == 0 && this.type === AttributeDataTypeEnum.Boolean) {
            this._options = DEFAULT_BOOLEAN_OPTIONS;
        }
    }
}
