import {
    DataEntryUIControlType,
    FormConfiguration,
    FormConfigurationCreateFromExistingPickerType,
    FormConfigurationEntityExtraFieldType,
    FormConfigurationOptionsDirection,
    GetFormConfigurationResponseData
} from "./get-form-configuration-response-data";

export const DHBA_FORM_CONFIGURATION_TESTDATA: FormConfiguration = {
    uuid: "generated UUID",
    businessId: "DHBA_test_patient_form",
    name: "dhba-2024",
    author: "Victoria Gardukevich",
    maintainer: "Victoria Gardukevich",
    version: "1.0.0",
    contractId: "strong contract UUID",
    datasets: [
        {
            businessAlias: "patient",
            contractId: "list strong contract UUID",
            label: "Patient",
            keyedVariables: ["land", "idcode"],
            attributes: [
                {
                    name: "id",
                    label: "Zorginstelling",
                    inputControl: DataEntryUIControlType.Text
                },
                {
                    name: "upn",
                    label: "Uniek Patiëntnummer",
                    help: "Het zieknhuis patiëntnummer (UPN) zoals deze in het elektronisch patiënten dossier (EPD) van het desbetreffende ziekenhuis staat geregistreerd.",
                    inputControl: DataEntryUIControlType.Text
                },
                {
                    name: "land",
                    label: "Land Burger Service Nummer",
                    inputControl: DataEntryUIControlType.Radio,
                    optionsDirection: FormConfigurationOptionsDirection.Vertical
                },
                {
                    name: "idcode",
                    label: "Burger Service Nummer",
                    inputControl: DataEntryUIControlType.Text
                },
                {
                    name: "tussen",
                    label: "Voorvoegsels",
                    inputControl: DataEntryUIControlType.Text
                },
                {
                    name: "naam",
                    label: "Geslachtsnaam",
                    inputControl: DataEntryUIControlType.Text
                },
                {
                    name: "gebdat",
                    label: "Geboortedatum",
                    inputControl: DataEntryUIControlType.Date
                },
                {
                    name: "geslacht",
                    label: "Geslacht",
                    inputControl: DataEntryUIControlType.Radio,
                    optionsDirection: FormConfigurationOptionsDirection.Vertical
                },
                {
                    name: "land-woon",
                    label: "Land (Woonadres)",
                    inputControl: DataEntryUIControlType.Select,
                    defaultValue: "nl"
                },
                {
                    name: "pcode",
                    label: "Postcode",
                    inputControl: DataEntryUIControlType.Text
                },
                {
                    name: "datovl",
                    label: "Datum overlijden (indien van toepassing)",
                    inputControl: DataEntryUIControlType.Date
                }
            ]
        },
        {
            contractId: "list strong contract UUID",
            businessAlias: "comorbiditeiten",
            label: "Commorbiditeiten",
            keyedVariables: ["datcom", "patient_upn"],
            settings: {
                createFromExisting: {
                    pickerType: FormConfigurationCreateFromExistingPickerType.LatestDate
                }
            },
            extraFields: [
                {
                    name: "test-setter",
                    type: FormConfigurationEntityExtraFieldType.Setter,
                    label: ".SetterLabel",
                    attributesToSet: ["commyo", "comhartfaal"],
                    options: [
                        {
                            value: "true",
                            label: "Yes to all"
                        },
                        {
                            value: "false",
                            label: "No to all"
                        }
                    ],
                    position: {
                        type: "after",
                        referenceItemName: "datcom"
                    }
                }
            ],
            attributes: [
                {
                    name: "datcom",
                    label: "Datum comorbiditeiten",
                    inputControl: DataEntryUIControlType.Date
                },
                {
                    name: "commyo",
                    label: "Myocardinfarct",
                    inputControl: DataEntryUIControlType.Radio,
                    optionsDirection: FormConfigurationOptionsDirection.Horizontal
                },
                {
                    name: "comhartfaal",
                    label: "Congestief hartfalen",
                    inputControl: DataEntryUIControlType.Radio,
                    optionsDirection: FormConfigurationOptionsDirection.Horizontal
                },
                {
                    name: "patient_upn",
                    label: "Uniek Patiëntnummer",
                    inputControl: DataEntryUIControlType.Text,
                    include: false
                }
            ]
        }
    ]
};

export const DCRA_2024_FORM_CONFIGURATION_TESTDATA: FormConfiguration = {
    uuid: "generated UUID",
    businessId: "DHBA_test_patient_form",
    name: "dcra-2024",
    author: "Victoria Gardukevich",
    maintainer: "Victoria Gardukevich",
    version: "1.0.0",
    contractId: "strong contract UUID",
    datasets: [
        {
            businessAlias: "patient",
            contractId: "list strong contract UUID",
            label: "Patient",
            keyedVariables: ["land", "idcode"],
            attributes: [
                {
                    name: "id",
                    label: "Zorginstelling"
                },
                {
                    name: "upn",
                    label: "Uniek Patiëntnummer",
                    help: "Het zieknhuis patiëntnummer (UPN) zoals deze in het elektronisch patiënten dossier (EPD) van het desbetreffende ziekenhuis staat geregistreerd."
                },
                {
                    name: "land",
                    label: "Land Burger Service Nummer",
                    inputControl: DataEntryUIControlType.Radio
                },
                {
                    name: "idcode",
                    label: "Burger Service Nummer"
                },
                {
                    name: "tussen",
                    label: "Voorvoegsels"
                },
                {
                    name: "naam",
                    label: "Geslachtsnaam"
                },
                {
                    name: "gebdat",
                    label: "Geboortedatum"
                },
                {
                    name: "geslacht",
                    label: "Geslacht",
                    inputControl: DataEntryUIControlType.Radio
                },
                {
                    name: "land-woon",
                    label: "Land (Woonadres)",
                    defaultValue: "nl"
                },
                {
                    name: "pcode",
                    label: "Postcode"
                },
                {
                    name: "datovl",
                    label: "Datum overlijden (indien van toepassing)"
                }
            ]
        },
        {
            contractId: "list strong contract UUID",
            businessAlias: "comorbiditeiten",
            label: "Commorbiditeiten",
            keyedVariables: ["datcom", "patient_upn"],
            settings: {
                createFromExisting: {
                    pickerType: FormConfigurationCreateFromExistingPickerType.LatestDate
                }
            },
            extraFields: [
                {
                    name: "test-setter",
                    type: FormConfigurationEntityExtraFieldType.Setter,
                    label: ".SetterLabel",
                    attributesToSet: ["commyo", "comhartfaal"],
                    options: [
                        {
                            value: "true",
                            label: "Yes to all"
                        },
                        {
                            value: "false",
                            label: "No to all"
                        }
                    ],
                    position: {
                        type: "after",
                        referenceItemName: "datcom"
                    }
                },
                {
                    name: "test-paragraph",
                    type: FormConfigurationEntityExtraFieldType.Paragraph,
                    label: ".ParagraphLabel",
                    position: {
                        type: "first"
                    },
                    standalone: true
                }
            ],
            attributes: [
                {
                    name: "datcom",
                    label: "Datum comorbiditeiten"
                },
                {
                    name: "commyo",
                    label: "Myocardinfarct",
                    optionsDirection: FormConfigurationOptionsDirection.Horizontal
                },
                {
                    name: "comhartfaal",
                    label: "Congestief hartfalen",
                    optionsDirection: FormConfigurationOptionsDirection.Horizontal
                },
                {
                    name: "patient_upn",
                    include: false
                }
            ]
        },
        {
            contractId: "list strong contract UUID",
            businessAlias: "presentatie",
            label: "Presentatie",
            keyedVariables: ["datpa1", "tumor_upn"],
            settings: {
                createFromExisting: {
                    pickerType: FormConfigurationCreateFromExistingPickerType.LatestDate
                }
            },
            attributes: [
                {
                    name: "datpa1",
                    label: "Datum presentatie"
                },
                {
                    name: "lengte",
                    label: "Lengte"
                },
                {
                    name: "gewicht",
                    label: "Gewicht"
                },
                {
                    name: "tumor_upn",
                    include: false
                }
            ]
        },
        {
            contractId: "list strong contract UUID",
            businessAlias: "diagnostiek",
            keyedVariables: ["presentatie_datpa1", "primcrc"],
            label: "Diagnostiek",
            settings: {
                createFromExisting: {
                    pickerType: FormConfigurationCreateFromExistingPickerType.LatestDate
                }
            },
            attributes: [
                {
                    name: "presentatie_datpa1",
                    include: false
                }
            ]
        }
    ]
};

import * as jsonData from "./form-configuration-enriched.json";

export const DCRA_2024_FULL_FC: FormConfiguration = jsonData as FormConfiguration;
export const GET_FORM_CONFIGURATION_RESPONSE_TESTDATA: GetFormConfigurationResponseData = [
    DCRA_2024_FULL_FC
];
