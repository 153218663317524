import { FormConfigurationSection, FormConfigurationSectionCategory } from "@fhir-api";
import { FhirEntity } from "./entity";
import { FHIRSubjectDataset } from "./subject-dataset";
import { FHIRSubject } from "./subject";

export type DatasetSectionType = "dataset" | "section";

export class DatasetSection {
    get subject(): FHIRSubject | null {
        return this._subject ?? null;
    }

    get type(): DatasetSectionType {
        return this._type;
    }

    get parentEntity(): FhirEntity {
        return this._parentEntity;
    }

    get isDataset(): boolean {
        return this._type === "dataset";
    }

    get isSection(): boolean {
        return this._type === "section";
    }

    get name(): string {
        return this._type === "section"
            ? (this._formConfiguration?.name ?? "")
            : (this._dataset?.name ?? "");
    }

    get label(): string {
        return this._type === "section"
            ? (this._formConfiguration?.label ?? "")
            : (this._dataset?.entity.label ?? "");
    }

    get attributes(): string[] | null {
        return this._type === "section" ? (this._formConfiguration?.attributes ?? []) : null;
    }

    get allAttributes(): string[] {
        const categoriesAttributes =
            this.categories?.map<string[]>(category => category.attributes).flat() ?? [];
        return (this.attributes ?? [])?.concat(categoriesAttributes);
    }

    get dataset(): FHIRSubjectDataset | null {
        return this._type === "dataset" ? (this._dataset ?? null) : null;
    }

    get categories(): FormConfigurationSectionCategory[] | null {
        return this._type === "section" ? (this._formConfiguration?.categories ?? null) : null;
    }

    constructor(
        private _type: DatasetSectionType,
        private _parentEntity: FhirEntity,
        private _formConfiguration?: FormConfigurationSection,
        private _dataset?: FHIRSubjectDataset,
        private _subject?: FHIRSubject
    ) {}

    setSubject(subject: FHIRSubject): DatasetSection {
        return new DatasetSection(
            this._type,
            this._parentEntity,
            this._formConfiguration,
            this._dataset,
            subject
        );
    }
}
