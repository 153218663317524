import {
    FormConfigurationCreateFromExistingSettings,
    FormConfigurationDataset,
    FormConfigurationDatasetParagraph,
    FormConfigurationEntityExtraField,
    FormConfigurationSection
} from "@fhir-api";
import { FhirAttribute } from "./attribute";
import { FhirEntityList } from "./entity-list";
import { ScmEntity } from "@logex/expression-validator";
import { DatasetSection, DatasetSectionType } from "./section";
import { FHIRSubject } from "./subject";
import { FhirDataset } from "./dataset";

export class FhirEntity {
    get name(): string {
        return this._parentEntityList.scm.businessAlias ?? this._scm.content.name;
    }

    get label(): string {
        return this._formConfiguration?.label ?? this.name;
    }

    get wordLabel(): string {
        return this.label.toLocaleLowerCase();
    }

    get startLabel(): string {
        if (this.wordLabel.length === 0) {
            return this.wordLabel;
        }
        const word = this.wordLabel;
        return word[0].toLocaleUpperCase() + word.substring(1);
    }

    get attributes(): FhirAttribute[] {
        return this._attributes;
    }

    get listLabel(): string {
        return this._formConfiguration?.datasetList?.label ?? this.label;
    }

    get listParagraphs(): FormConfigurationDatasetParagraph[] {
        return this._formConfiguration?.datasetList?.paragraphs ?? [];
    }

    get scm(): ScmEntity {
        return this._scm;
    }

    get sections(): DatasetSection[] {
        return this._sections;
    }

    get preferredOrder(): number {
        return this.parentEntityList.scm.preferredOrder;
    }

    get formConfiguration(): FormConfigurationDataset | undefined {
        return this._formConfiguration;
    }

    get parentEntityList(): FhirEntityList {
        return this._parentEntityList;
    }

    get extraFields(): FormConfigurationEntityExtraField[] | undefined {
        return this._formConfiguration?.extraFields;
    }

    get dataset(): FhirDataset {
        return this._parentEntityList.parentEntityListGroup.parentDataset;
    }

    get createFromCopy(): FormConfigurationCreateFromExistingSettings | null {
        return this._parentEntityList?.formConfiguration?.settings?.createFromExisting ?? null;
    }
    /**
     * Returns the key variables for this dataset.
     * These variables are used when creating a new subject.
     */
    get keyVariables(): FhirAttribute[] {
        const keyVariableNames = this._formConfiguration?.keyedVariables ?? this._scm.primaryKey;
        return this._attributes.filter(
            attribute => keyVariableNames.includes(attribute.name) && attribute.included
        );
    }

    private _attributes: FhirAttribute[] = [];
    private _attributesLookup: Record<string, FhirAttribute> | null = null;
    private _sections: DatasetSection[] = [];
    private _sectionsLookup: Record<string, DatasetSection> | null = null;

    constructor(
        private _parentEntityList: FhirEntityList,
        private _scm: ScmEntity,
        private _formConfiguration?: FormConfigurationDataset
    ) {
        this._setAttributes();
        this._setSections();
    }

    getVariable(name: string): FhirAttribute | null {
        return this._attributesLookup?.[name] ?? null;
    }

    getDefaultSection(): DatasetSection | null {
        return this._sections.length > 0 ? this._sections[0] : null;
    }

    private _setAttributes(): void {
        for (const scmAttribute of this._scm.content.attributes) {
            const fcAttribute = this._formConfiguration?.attributes?.find(
                ({ name }) => name === scmAttribute.name
            );
            const modifiedAttribute = new FhirAttribute(
                this,
                scmAttribute,
                this._scm.content.attachedExtension || [],
                fcAttribute
            );
            if (!this._attributesLookup) {
                this._attributesLookup = {};
            }
            this._attributes.push(modifiedAttribute);
            this._attributesLookup[scmAttribute.name] = modifiedAttribute;
        }
    }

    createNewSection(subject: FHIRSubject): DatasetSection {
        const newSection: FormConfigurationSection = {
            name: this.name,
            label: "New " + this.label,
            attributes: this.keyVariables.map(attribute => attribute.name)
        };
        const datasetSection = new DatasetSection("section", this, newSection, undefined, subject);
        return datasetSection;
    }

    private _setSections(): void {
        const defaultSection: FormConfigurationSection = {
            name: this.name,
            label: this.label + " Details",
            attributes: this.attributes
                .filter(({ included }) => included)
                .map(attribute => attribute.name)
        };
        for (const section of this._formConfiguration?.sections ?? [defaultSection]) {
            const datasetSection = new DatasetSection("section", this, section);
            this._sections.push(datasetSection);
            if (!this._sectionsLookup) {
                this._sectionsLookup = {};
            }
            this._sectionsLookup[section.name] = datasetSection;
        }
    }
}
