import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, LOCALE_ID, inject, provideAppInitializer } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule, TranslateCompiler } from "@ngx-translate/core";
import { MarkdownModule } from "ngx-markdown";

import { UiCoreModule } from "@logex/framework/ui-core";
import { LgConsoleConfiguration, LG_APPLICATION_MULTI_EVENT_TRACERS } from "@logex/framework/core";
import {
    ReferenceTranslateCompiler,
    useMessageFormatLocales,
    LG_LOCALIZATION_SETTINGS,
    LgLocalizationModule
} from "@logex/framework/lg-localization";
import { LgLocalStorageFwUiStateService, LG_FW_UI_STATE_SERVICE } from "@logex/framework/lg-layout";
import {
    LgApplicationModule,
    LgMatTrackingService,
    LgApplicationInsightsService,
    LG_MATOMO_CONFIGURATION,
    LG_APPLICATION_INSIGHTS_CONFIGURATION,
    LG_NAVIGATION,
    LG_USER_INFO,
    LG_APP_CONFIGURATION,
    LG_APP_SESSION,
    LG_APP_INFO,
    LG_USERFLOW_SERVICE,
    LgUserflowService,
    LG_USERFLOW_CONFIGURATION,
    LgUserflowConfiguration,
    LG_AUTHENTICATION_SERVICE
} from "@logex/framework/lg-application";

import {
    Auth0InterceptorService,
    AUTH0_CONFIG,
    AuthorizationModule,
    MRDM_AUTH_API_URL,
    Auth0AuthenticationService
} from "@authorization";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { AccessDeniedComponent } from "./access-denied/access-denied.component";

import { AppApplicationInsightsConfigurationService } from "./services/tracking/app-application-insights-configuration.service";
import { AppLocalizationSettings } from "./services/localization/app-localization.service";
import { AppConfigurationLoaderService } from "./services/configuration/app-configuration-loader.service";
import { AppConfigurationService } from "./services/configuration/app-configuration.service";
import { AppMatomoConfiguration } from "./services/tracking/app-matomo-configuration.service";
import { AppStateService } from "./services/state/app-state.service";
import { FHIR_MIDDLEWARE_SERVICE_API_URL } from "@fhir-api";

@NgModule({
    declarations: [AppComponent, AccessDeniedComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        MarkdownModule.forRoot(),
        TranslateModule.forRoot({
            compiler: { provide: TranslateCompiler, useClass: ReferenceTranslateCompiler }
        }),
        LgApplicationModule,
        LgLocalizationModule.forRoot({
            localizationUrlPrefix: "localization"
        }),
        AuthorizationModule.forRoot(),
        UiCoreModule,
        FormsModule
    ],
    providers: [
        {
            provide: LgConsoleConfiguration,
            useFactory: () => new LgConsoleConfiguration()
        },
        useMessageFormatLocales(["nl", "nl-NL", "en", "en-GB"]),
        {
            provide: LOCALE_ID,
            useValue: "nl-NL"
        },
        {
            provide: LG_LOCALIZATION_SETTINGS,
            useClass: AppLocalizationSettings
        },
        provideAppInitializer(() => {
            const initializerFn = ((
                loaderService: AppConfigurationLoaderService,
                configurationService: AppConfigurationService
            ) => {
                return async () => {
                    const configuration = await loaderService.loadConfig();
                    configurationService.setConfiguration(configuration);
                };
            })(inject(AppConfigurationLoaderService), inject(AppConfigurationService));
            return initializerFn();
        }),
        {
            provide: AUTH0_CONFIG,
            useFactory: (configurationService: AppConfigurationService) => {
                return configurationService.getAuth0ConfigAsync();
            },
            deps: [AppConfigurationService]
        },
        {
            provide: LG_APP_CONFIGURATION,
            useFactory: (configurationService: AppConfigurationService) => {
                const userflow = configurationService.getUserflowConfig();
                return {
                    userflowContentId: userflow.contentId,
                    userflowEnvironmentKey: userflow.environmentKey,
                    ready: Promise.resolve()
                };
            },
            deps: [AppConfigurationService]
        },
        {
            provide: LG_APP_INFO,
            useFactory: () => ({})
        },
        {
            provide: LG_APP_SESSION,
            useFactory: () => ({})
        },
        {
            provide: MRDM_AUTH_API_URL,
            useFactory: (configurationService: AppConfigurationService) =>
                configurationService.getMrdmAuthApiConfig().url,
            deps: [AppConfigurationService]
        },
        {
            provide: LG_MATOMO_CONFIGURATION,
            useExisting: AppMatomoConfiguration
        },
        {
            provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
            useExisting: LgMatTrackingService,
            multi: true
        },
        {
            provide: LG_APPLICATION_INSIGHTS_CONFIGURATION,
            useExisting: AppApplicationInsightsConfigurationService
        },
        {
            provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
            useExisting: LgApplicationInsightsService,
            multi: true
        },
        /* {
            provide: ErrorHandler,
            useClass: LgErrorHandler
        },*/
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Auth0InterceptorService,
            multi: true
        },
        {
            provide: LG_FW_UI_STATE_SERVICE,
            useClass: LgLocalStorageFwUiStateService
        },
        {
            provide: LG_NAVIGATION,
            useFactory: (state: AppStateService) => state.navigation$,
            deps: [AppStateService]
        },
        {
            provide: LG_USER_INFO,
            useValue: {}
        },
        {
            provide: LG_USERFLOW_SERVICE,
            useExisting: LgUserflowService
        },
        {
            provide: LG_USERFLOW_CONFIGURATION,
            useClass: LgUserflowConfiguration
        },
        {
            provide: LG_AUTHENTICATION_SERVICE,
            useClass: Auth0AuthenticationService
        },
        {
            provide: FHIR_MIDDLEWARE_SERVICE_API_URL,
            useFactory: (configurationService: AppConfigurationService) =>
                configurationService.getApiConfig().fhirMiddlewareServiceUrl,
            deps: [AppConfigurationService]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
