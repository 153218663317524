import { FormConfiguration } from "@fhir-api";
import { FhirEntityList } from "./entity-list";
import { ScmEntityGroup, ScmTypeEnum } from "@logex/expression-validator";
import { FhirDataset } from "./dataset";
import { FhirEntity } from "./entity";

export class FhirEntityListGroup {
    private _entityLists: FhirEntityList[] = [];

    get scm(): ScmEntityGroup<ScmTypeEnum> {
        return this._scm;
    }

    get formConfiguration(): FormConfiguration | undefined {
        return this._formConfiguration;
    }

    get entityLists(): FhirEntityList[] {
        return this._entityLists;
    }

    get entities(): FhirEntity[] {
        return this._entityLists.map(entityList => entityList.entity);
    }

    get parentDataset(): FhirDataset {
        return this._parentDataset;
    }

    constructor(
        private _parentDataset: FhirDataset,
        private _scm: ScmEntityGroup<ScmTypeEnum>,
        private _formConfiguration?: FormConfiguration
    ) {
        this._entityLists = _scm?.content?.childContract.map((entityList, index) => {
            const fcEntityList = this._formConfiguration?.datasets.find(
                ({ businessAlias }) => businessAlias === entityList.businessAlias
            );

            return new FhirEntityList(this, entityList, fcEntityList);
        });
    }
}
