import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    distinctUntilChanged,
    filter,
    first,
    map,
    Observable,
    of,
    switchMap,
    throwError
} from "rxjs";
import { FHIRMiddlewareApiService, ProjectResourceData } from "@fhir-api";
import { tap } from "rxjs/operators";
import { FhirDataset, ProjectPermissions } from "../model";
import { AppDialogService } from "./app-dialog.service";
import { AppStateService } from "./state/app-state.service";
@Injectable({
    providedIn: "root"
})
export class AppProjectsService {
    private _appStateService = inject(AppStateService);
    private _middlewareApiService = inject(FHIRMiddlewareApiService);
    private _router = inject(Router);
    private _appDialogService = inject(AppDialogService);

    private _projects = new BehaviorSubject<ProjectResourceData[]>([]);
    private _selectedProject = new BehaviorSubject<FhirDataset | null>(null);
    private _selectedProjectPermissions = new BehaviorSubject<ProjectPermissions | null>(null);

    public readonly projects$ = this._projects.pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );

    public readonly selectedProject$ = this._selectedProject.asObservable();
    public readonly filteredSelectedProject$ = this._selectedProject
        .asObservable()
        .pipe(filter((project): project is FhirDataset => project != null));

    public readonly selectedProjectPermissions$ = this._selectedProjectPermissions
        .asObservable()
        .pipe(filter((projectPerms): projectPerms is ProjectPermissions => projectPerms != null));

    // --------------------------------------------------------------------------------------
    init(): void {
        this._setSelectedProject();
        this._setSelectedProjectPermission();
    }

    private _setSelectedProject(): void {
        combineLatest([
            this._appStateService.selectedOrganizationId$,
            this._appStateService.selectedOrganizationUri$,
            this._appStateService.selectedRegistryName$
        ])
            .pipe(
                switchMap(([organizationId, organizationUri, selectedRegistryName]) =>
                    combineLatest([
                        this._middlewareApiService
                            .getFormConfiguration$(organizationId, selectedRegistryName)
                            .pipe(
                                this._appStateService.catchRequestError$({
                                    default: {
                                        action: () => this._router.navigate(["/select"])
                                    }
                                })
                            ),
                        this._middlewareApiService
                            .getScm$(organizationId, selectedRegistryName)
                            .pipe(
                                this._appStateService.catchRequestError$({
                                    default: {
                                        action: () => this._router.navigate(["/select"])
                                    }
                                })
                            )
                    ]).pipe(
                        first(),
                        map(([formConfiguration, scm]) => {
                            if (formConfiguration.body && scm.body) {
                                const formConfigurationData = formConfiguration?.body[0];
                                const scmData = scm.body;
                                try {
                                    return new FhirDataset(
                                        selectedRegistryName,
                                        this,
                                        scmData,
                                        formConfigurationData,
                                        {
                                            organizationUri,
                                            organizationId
                                        }
                                    );
                                } catch (err) {
                                    console.error(err);
                                    return null;
                                }
                            }

                            return null;
                        })
                    )
                )
            )
            .subscribe(project => {
                if (!project) {
                    this._appDialogService.displayErrorMessage("Project data are missing.");
                    return;
                }

                this.selectProject(project);
            });
    }

    private _setSelectedProjectPermission(): void {
        combineLatest([
            this._appStateService.selectedOrganizationId$,
            this._appStateService.selectedRegistryName$
        ])
            .pipe(
                switchMap(([organizationId, selectedRegistryName]) =>
                    this._middlewareApiService
                        .getProjectPermissions$(organizationId, selectedRegistryName)
                        .pipe(this._appStateService.catchRequestError$())
                )
            )
            .subscribe(response => {
                const canCreateSubject = response?.body?.permissions.rootSubjectCreate ?? false;
                const projectPermissions = new ProjectPermissions({ canCreateSubject });
                this.selectProjectPermissions(projectPermissions);
            });
    }

    // --------------------------------------------------------------------------------------
    loadProjects$(organisationCode: number): Observable<ProjectResourceData[] | null> {
        return this._middlewareApiService.getProjects$(organisationCode).pipe(
            map(response => response?.body ?? null),
            tap(projects => this._setProjects(projects ?? [])),
            catchError((error: HttpErrorResponse) => {
                this._appDialogService.displayErrorMessage("Failed to load projects.");
                return throwError(() => error);
            })
        );
    }

    getProjectByUri(projectUri: string): ProjectResourceData | undefined {
        return this._projects.getValue().find(project => project.uri === projectUri);
    }

    selectProject(project: FhirDataset): void {
        this._selectedProject.next(project);
    }

    resetSelectedProject(): void {
        this._selectedProject.next(null);
    }

    selectProjectPermissions(permission: ProjectPermissions): void {
        this._selectedProjectPermissions.next(permission);
    }

    private _setProjects(projects: ProjectResourceData[]): void {
        const filteredProjects = projects?.filter(project => project.properties.selectable) ?? null;
        this._projects.next(filteredProjects);
    }
}
