import { FhirEntityListGroup } from "./entity-list-group";
import { FHIRSubject } from "./subject";
import { FhirAttribute } from "./attribute";
import { DatasetData } from "@fhir-api";
import {
    AttributeDataTypeEnum,
    evaluateRootDataset,
    RootDatasetData,
    Scm,
    ScmEvaluation,
    ScmTypeEnum,
    SubjectDatasetEvaluation,
    SubjectEvaluation,
    ValidatorDatasetData
} from "@logex/expression-validator";
import { FhirEntity } from "./entity";
import { FhirEntityList } from "./entity-list";
import { FhirDataset } from "./dataset";

interface FHIRSubjectDatasetConstructorOptions {
    parentSubject?: FHIRSubject | string; // string is the project url
    parentDataset?: FHIRSubjectDataset;
}

export class FHIRSubjectDataset {
    static create(
        data: DatasetData,
        entityList: FhirEntityList,
        options: FHIRSubjectDatasetConstructorOptions
    ): FHIRSubjectDataset {
        return new FHIRSubjectDataset(data, entityList, options);
    }

    static createEmpty(
        name: string,
        entityList: FhirEntityList,
        options: FHIRSubjectDatasetConstructorOptions
    ): FHIRSubjectDataset {
        const data: DatasetData = {
            name,
            subject: []
        };

        return FHIRSubjectDataset.create(data, entityList, options);
    }

    getValidatorData(creation: boolean = false): ValidatorDatasetData {
        return {
            name: this._data.name,
            subject: this._subjects.map(subject => {
                return {
                    name: subject.name,
                    variable: subject.variables.reduce(
                        (acc, variable) => {
                            if (variable.value !== undefined || !creation) {
                                acc[variable.name] = variable.value;
                            }
                            return acc;
                        },
                        {} as Record<string, string | undefined>
                    ),
                    dataset: subject.datasets.map(dataset => dataset.getValidatorData(creation))
                };
            })
        };
    }

    get url(): string {
        return this._url;
    }

    get apiPath(): string {
        return this._apiPath;
    }

    get name(): string {
        return this._data.name;
    }

    get subjects(): FHIRSubject[] {
        return this._subjects;
    }

    get latestSubject(): FHIRSubject | null {
        return this._subjects[0] ?? null;
    }

    get parentDataset(): FHIRSubjectDataset | null {
        return this._parentDataset;
    }

    get parentSubject(): FHIRSubject | null {
        return this._parentSubject;
    }

    get entity(): FhirEntity {
        return this._entityList.entity;
    }

    get entityList(): FhirEntityList {
        return this._entityList;
    }

    get entityListGroup(): FhirEntityListGroup {
        return this._entityList.parentEntityListGroup;
    }

    get dataset(): FhirDataset {
        return this._entityList.parentEntityListGroup.parentDataset;
    }

    private _url = "";
    private _apiPath = "";
    private _parentDataset: FHIRSubjectDataset | null;
    private _parentSubject: FHIRSubject | null;
    private _subjects: FHIRSubject[] = [];
    private _subjectsLookup: Record<string, FHIRSubject> = {};
    private _validations: SubjectEvaluation[] = [];

    constructor(
        private _data: DatasetData,
        private _entityList: FhirEntityList,
        { parentSubject, parentDataset }: FHIRSubjectDatasetConstructorOptions
    ) {
        if (typeof parentSubject === "string") {
            this._parentSubject = null;
            this._url = parentSubject;
        } else if (parentSubject) {
            this._parentSubject = parentSubject;
            this._url = `${this._parentSubject.url}/${this._data.name}`;
        } else {
            this._parentSubject = null;
        }

        this._parentDataset = parentDataset ?? null;

        if (this._parentDataset) {
            this._apiPath = `${this._parentDataset.apiPath}.${this._data.name}`;
        } else {
            this._apiPath = this._data.name;
        }

        this._processSubjects();
    }

    createNewSubject({
        keyVariables,
        defaultValues
    }: {
        keyVariables?: FhirAttribute[];
        defaultValues?: Record<string, string | null>;
    } = {}): FHIRSubject {
        const subject = FHIRSubject.createEmpty({
            parentDataset: this,
            parentSubject: this._parentSubject,
            keyVariables,
            defaultValues
        });
        this._subjects.push(subject);
        this._subjectsLookup[subject.name] = subject;

        return subject;
    }

    getSubject(name: string): FHIRSubject | null {
        return this._subjectsLookup[name] ?? null;
    }

    updateDatasetValidation(evaluation: SubjectDatasetEvaluation): void {
        for (const subjectEvaluation of evaluation.subjects) {
            this._subjectsLookup[subjectEvaluation.name].updateSubjectValidation(subjectEvaluation);
            for (const childDataset of this._subjectsLookup[subjectEvaluation.name].datasets) {
                const sectionEvaluation = subjectEvaluation.datasets.find(
                    dataset => dataset.name === childDataset.name
                ) || {
                    name: childDataset.name,
                    subjects: [] // TODO maybe also prefill or solve the empty subjects array case when subjects are not empty
                };
                childDataset.updateDatasetValidation(sectionEvaluation);
            }
        }
    }

    public validateSubject(creation: boolean = false): ScmEvaluation | null {
        const project = this.entity.parentEntityList.parentEntityListGroup.parentDataset;
        const values: RootDatasetData = {
            data: this.getValidatorData(creation)
        };
        let validatedData: ScmEvaluation;
        try {
            validatedData = evaluateRootDataset(project.scm, values, creation);
        } catch (error) {
            return null;
        }
        this.updateDatasetValidation(validatedData.rootSubjectDataset);
        return validatedData;
    }

    private _resetSubjects(): void {
        this._subjects = [];
        this._subjectsLookup = {};
    }

    private _processSubjects(): void {
        for (const subjectData of this._data.subject ?? []) {
            const subject = new FHIRSubject(subjectData, {
                parentDataset: this,
                parentSubject: this._parentSubject
            });

            this._subjects.push(subject);
            this._subjectsLookup[subjectData.name] = subject;
        }
        const keyVariableNames = this.entity.scm.primaryKey;
        this._subjects.sort((a, b) => {
            let firstValue = a.getVariable(keyVariableNames[0])?.value ?? "";
            let secondValue = b.getVariable(keyVariableNames[0])?.value ?? "";
            return -firstValue.localeCompare(secondValue);
        });
    }
}
