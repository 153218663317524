import {
    DatasetData,
    FormConfigurationDataset,
    FormConfigurationDatasetRelation,
    FormConfigurationExtension,
    FormConfigurationSection,
    FormConfigurationSectionCategoryFieldType,
    SubjectData,
    SubjectVariableData
} from "@fhir-api";

import { SubjectDataset } from "./subject-dataset";
import { SubjectVariable } from "./subject-variable";

import {
    createEntitiesLookup,
    evaluateRootDataset,
    ExpressionContext,
    extractEntityLists,
    FieldsAvailability,
    FieldsOptionsAvailability,
    QualityRuleScope,
    RootDatasetData,
    ruleLogicalExpression,
    ScmEntityList,
    ScmEvaluation,
    SeverityEnum,
    SubjectEvaluation,
    ValidatorDatasetData
} from "@logex/expression-validator";
import { Project } from "./project";
import { uniqueFilter } from "@app/util/uniq-util";
import { DataentryNavigationLink } from "@app/components";
import { Subject } from "rxjs";

export interface TranslationMessage {
    message: Record<string, string>;
    messageVariables?: Record<string, string | number>;
    severity: SeverityEnum;
}

export const SeverityPriorities = {
    [SeverityEnum.Warning]: 0,
    [SeverityEnum.Major]: 1,
    [SeverityEnum.Critical]: 2
};

export class SubjectInstance {
    static create(
        parentDataset: SubjectDataset,
        parentSubject: SubjectInstance | null,
        data: SubjectData,
        unpublishableErrors: SeverityEnum[] = [SeverityEnum.Critical],
        popupErrors: SeverityEnum[] = [SeverityEnum.Critical, SeverityEnum.Major]
    ): SubjectInstance {
        return new SubjectInstance(
            parentDataset,
            parentSubject,
            data,
            unpublishableErrors,
            popupErrors
        );
    }

    get name(): string {
        return this._name;
    }

    get label(): string {
        const label = this.keyVariables
            .map(variable => {
                const subjectVariable = this.getVariable(variable.name);
                return variable.getValueLabel(subjectVariable?.value);
            })
            .join("/");
        return label.length > 0 ? label : "-";
    }

    get wordLabel(): string {
        return this.label.toLocaleLowerCase();
    }

    get startLabel(): string {
        if (this.wordLabel.length === 0) {
            return this.wordLabel;
        }
        const word = this.wordLabel;
        return word[0].toLocaleUpperCase() + word.substring(1);
    }

    get attributes(): SubjectVariable[] {
        return this._subjectVariables;
    }

    get sections(): FormConfigurationSection[] {
        return (
            this.formConfiguration?.sections.filter(
                section => this._sectionsVisibility[section.name]
            ) ?? []
        );
    }

    get extensions(): FormConfigurationExtension[] | undefined {
        return this.formConfiguration?.extensions;
    }

    get datasetRelations(): FormConfigurationDatasetRelation[] | undefined {
        return this.formConfiguration?.datasets;
    }

    get validations(): SubjectEvaluation | null {
        return this._validations ?? null;
    }

    get url(): string {
        return this._url;
    }

    get apiPath(): string {
        return this._apiPath;
    }

    get urlSegments(): string[] {
        return [this._parentDataset.name, this.name];
    }

    get canDelete(): boolean {
        return true;
    }

    get parentDataset(): SubjectDataset {
        return this._parentDataset;
    }

    get datasets(): SubjectDataset[] {
        return this._subjectDatasets;
    }

    get parentSubject(): SubjectInstance | null {
        return this._parentSubject;
    }

    get variables(): SubjectVariable[] {
        return this._subjectVariables;
    }

    get visible(): boolean {
        for (const variable of this._subjectVariables) {
            if (variable.visible) {
                return true;
            }
        }
        return false;
    }

    get valid(): boolean {
        return (
            this.getValidationMessages(true).filter(message =>
                this.unpublishableErrors.includes(message.severity)
            ).length === 0
        );
    }

    get hasErrors(): boolean {
        return this.errorCount > 0;
    }

    get errorCount(): number {
        return this.getValidationMessages(true).filter(message =>
            this.popupErrors.includes(message.severity)
        ).length;
    }

    get scm(): ScmEntityList {
        return this._parentDataset.scm;
    }

    get formConfiguration(): FormConfigurationDataset | undefined {
        return this._parentDataset.formConfiguration;
    }

    get project(): Project {
        return this._parentDataset.project;
    }

    /**
     * Returns the key variables for this dataset.
     * These variables are used when creating a new subject.
     */
    get keyVariables(): SubjectVariable[] {
        const keyVariableNames =
            this.formConfiguration?.keyedVariables ?? this.scm.content.childContract.primaryKey;
        return this._subjectVariables.filter(
            attribute => keyVariableNames.includes(attribute.name) && attribute.included
        );
    }

    variablesUpdated(variableNames: string[]): void {
        this.variableUpdated.next(variableNames);
    }

    variableUpdated = new Subject<string[]>();
    private _url = "";
    private _name!: string;
    private _apiPath = "";
    private _subjectDatasets: SubjectDataset[] = [];
    private _subjectDatasetsLookup: Record<string, SubjectDataset> = {};
    private _subjectDatasetsNameLookup: Record<string, SubjectDataset> = {};
    private _subjectVariables: SubjectVariable[] = [];
    private _subjectVariablesLookup: Record<string, SubjectVariable> = {};
    private _validations: SubjectEvaluation | null = null;
    private _sectionsVisibility: Record<string, boolean> = {};

    constructor(
        private _parentDataset: SubjectDataset,
        private _parentSubject: SubjectInstance | null,
        _data: SubjectData,
        private unpublishableErrors: SeverityEnum[] = [SeverityEnum.Critical],
        private popupErrors: SeverityEnum[] = [SeverityEnum.Critical, SeverityEnum.Major]
    ) {
        this._name = _data.name;
        if (!this.parentSubject) {
            // root subject
            this._url = `subject/${this.name}`;
        } else {
            this._url += `${_parentDataset.url}/${this.name}`;
        }

        if (this._parentSubject) {
            this._apiPath = `${this._parentSubject.apiPath}.${this.name}`;
        } else {
            this._apiPath = this.name;
        }
        const datasetDataLookup: Record<string, DatasetData> = (_data?.dataset ?? []).reduce(
            (acc, dataset) => {
                acc[dataset.name] = dataset;
                return acc;
            },
            {} as Record<string, DatasetData>
        );
        const variableDataLookup: Record<string, SubjectVariableData> = (
            _data?.variable ?? []
        ).reduce(
            (acc, dataset) => {
                acc[dataset.name] = dataset;
                return acc;
            },
            {} as Record<string, SubjectVariableData>
        );
        this._processDatasets(datasetDataLookup);
        this._processVariables(variableDataLookup);
    }

    getSection(name: string): FormConfigurationSection | undefined {
        return this.sections.find(section => section.name === name);
    }

    getDefaultSection(): FormConfigurationSection | undefined {
        return this.sections.at(0);
    }

    getNextSection(name: string, registryName: string): DataentryNavigationLink | null {
        let dataset = this.getDataset(name);

        /**
         * is dataset and is not empty
         */
        if (dataset && dataset.subjects.length > 0) {
            return this.getNextRoute(dataset.name, this, registryName);
        } else {
            let currentIndex = this.sections.findIndex(section => section.name == name);
            if (this.sections.length - 1 > currentIndex) {
                let nextSection = this.sections[currentIndex + 1];
                return this.getNextRoute(nextSection.name, this, registryName);
            } else {
                /**
                 * is last
                 */
                let currentSubject: SubjectInstance | null = this;
                let currentDataset: SubjectDataset | null = this.parentDataset;

                while (currentDataset!.name != this.getRootDataset().name) {
                    let nextSubject = this.getNextSubject(currentSubject?.name, currentDataset);
                    if (nextSubject == undefined) {
                        /**
                         * last subject in dataset
                         */
                        let nextSectionName = this.getNextInParentSubject(
                            currentDataset?.name,
                            currentSubject?.parentSubject
                        );

                        if (nextSectionName == undefined) {
                            /**
                             * last section
                             */
                            currentDataset = currentDataset!.parentDataset;
                            currentSubject = currentSubject!.parentSubject;
                        } else {
                            /**
                             * there is another section in dataset
                             */
                            return this.getNextRoute(
                                nextSectionName,
                                currentSubject!.parentSubject,
                                registryName
                            );
                        }
                    } else {
                        /**
                         * there is another subject in dataset
                         */
                        return this.getNextRoute(
                            nextSubject.sections[0].name,
                            nextSubject,
                            registryName
                        );
                    }
                }
            }
        }

        return null;
    }

    getPreviousSection(name: string, registryName: string): DataentryNavigationLink | null {
        let dataset = this.getDataset(name);

        /**
         * is dataset and is not empty
         */
        if (dataset && dataset.subjects.length > 0) {
            return this.getPreviousRoute(dataset.name, this, registryName);
        } else {
            let currentIndex = this.sections.findIndex(section => section.name == name);
            if (currentIndex > 0) {
                let previousSection = this.sections[currentIndex - 1];
                return this.getPreviousRoute(previousSection.name, this, registryName);
            } else {
                /**
                 * is first
                 */
                let currentSubject: SubjectInstance | null = this;
                let currentDataset: SubjectDataset | null = this.parentDataset;
                while (currentDataset!.name != this.getRootDataset().name) {
                    let previousSubject = this.getPreviousSubject(
                        currentSubject?.name,
                        currentDataset
                    );
                    if (previousSubject == undefined) {
                        /**
                         * first subject in dataset
                         */
                        let previousSectionName = this.getPreviousInParentSubject(
                            currentDataset?.name,
                            currentSubject?.parentSubject
                        );
                        if (previousSectionName == undefined) {
                            /**
                             * first section
                             */
                            currentDataset = currentDataset!.parentDataset;
                            currentSubject = currentSubject!.parentSubject;
                        } else {
                            /**
                             * there is another section in dataset
                             */
                            return this.getPreviousRoute(
                                previousSectionName,
                                currentSubject!.parentSubject,
                                registryName
                            );
                        }
                    } else {
                        /**
                         * there is another subject in dataset
                         */
                        return this.getPreviousRoute(
                            previousSubject.sections.at(-1)!.name,
                            previousSubject,
                            registryName
                        );
                    }
                }
            }
        }

        return null;
    }

    getFirstSection(dataset: SubjectDataset): FormConfigurationSection | undefined {
        let firstSubject = dataset.subjects.at(0);
        return firstSubject?.sections.at(0);
    }

    getLastSection(dataset: SubjectDataset): FormConfigurationSection | undefined {
        let lastSubject = dataset.subjects.at(-1);
        return lastSubject?.sections.at(-1);
    }

    getNextInDataset(currentDatasetName: string): string | undefined {
        let dataset = this.getDataset(currentDatasetName);
        let parentDataset = dataset?.parentDataset;

        let sectionIndex = parentDataset!.sections.findIndex(
            section => section.name == currentDatasetName
        );

        if (sectionIndex < parentDataset!.sections.length - 1 && sectionIndex >= 0) {
            return parentDataset!.sections[sectionIndex + 1].name;
        }

        return undefined;
    }

    getPreviousInDataset(currentDatasetName: string): string | undefined {
        let dataset = this.getDataset(currentDatasetName);
        let parentDataset = dataset?.parentDataset;

        let sectionIndex = parentDataset!.sections.findIndex(
            section => section.name == currentDatasetName
        );

        if (sectionIndex > 0) {
            return parentDataset!.sections[sectionIndex - 1].name;
        }

        return undefined;
    }

    getNextSubject(
        currentSubjectName: string | undefined,
        currentDataset: SubjectDataset | null
    ): SubjectInstance | undefined {
        let index =
            currentDataset?.subjects.findIndex(subject => subject.name == currentSubjectName) ?? -1;

        if (index < currentDataset!.subjects.length - 1 && index >= 0) {
            return currentDataset!.subjects[index + 1];
        }

        return undefined;
    }

    getPreviousSubject(
        currentSubjectName: string | undefined,
        currentDataset: SubjectDataset | null
    ): SubjectInstance | undefined {
        let index = currentDataset!.subjects.findIndex(
            subject => subject.name == currentSubjectName
        );

        if (index > 0) {
            return currentDataset!.subjects[index - 1];
        }

        return undefined;
    }

    getNextInParentSubject(
        currentSectionName?: string,
        parentSubject?: SubjectInstance | null
    ): string | undefined {
        let index =
            parentSubject?.sections.findIndex(section => section.name == currentSectionName) ?? -1;

        if (index < parentSubject!.sections.length - 1 && index >= 0) {
            return parentSubject?.sections[index + 1].name;
        } else {
            return undefined;
        }
    }

    getPreviousInParentSubject(
        currentSectionName?: string,
        parentSubject?: SubjectInstance | null
    ): string | undefined {
        let index =
            parentSubject?.sections.findIndex(section => section.name == currentSectionName) ?? -1;

        if (index > 0) {
            return parentSubject?.sections[index - 1].name;
        } else {
            return undefined;
        }
    }

    getNextRoute(
        nextSectionName: string,
        currentSubject: SubjectInstance | null,
        registryName: string
    ): DataentryNavigationLink {
        let dataset = this.getDatasetInSubject(currentSubject, nextSectionName);
        if (dataset) {
            let firstSection = this.getFirstSection(dataset);
            if (firstSection) {
                return {
                    route: `/${registryName}/${dataset.subjects[0].url}`,
                    queryParams: { section: firstSection?.name }
                };
            }
        }

        return {
            route: `/${registryName}/${currentSubject!.url}`,
            queryParams: { section: nextSectionName }
        };
    }

    getPreviousRoute(
        previousSectionName: string,
        currentSubject: SubjectInstance | null,
        registryName: string
    ): DataentryNavigationLink {
        let dataset = this.getDatasetInSubject(currentSubject, previousSectionName);
        if (dataset) {
            let lastSection = this.getLastSection(dataset);
            if (lastSection) {
                return {
                    route: `/${registryName}/${dataset.subjects.at(-1)?.url}`,
                    queryParams: { section: lastSection?.name }
                };
            }
        }

        return {
            route: `/${registryName}/${currentSubject!.url}`,
            queryParams: { section: previousSectionName }
        };
    }

    getDatasetInSubject(subject: SubjectInstance | null, name: string): SubjectDataset | null {
        return (
            subject!._subjectDatasetsLookup[name] ?? subject!._subjectDatasetsLookup[name] ?? null
        );
    }

    getDataset(name: string): SubjectDataset | null {
        return this._subjectDatasetsLookup[name] ?? this._subjectDatasetsNameLookup[name] ?? null;
    }

    getRootDataset(): SubjectDataset {
        return this.project.rootDataset;
    }

    getRootSubject(): SubjectInstance {
        let root = this._parentSubject;
        while (root?.parentSubject) {
            root = root.parentSubject;
        }
        return root ?? this;
    }

    getVariable(variableName: string): SubjectVariable | undefined {
        return this._subjectVariablesLookup[variableName];
    }

    getExtension(extensionName: string): FormConfigurationExtension | undefined {
        return this.extensions?.find(extension => extension.name === extensionName);
    }

    getDatasetRelation(relationName: string): FormConfigurationDatasetRelation | undefined {
        return this.datasetRelations?.find(relation => relation.name === relationName);
    }

    getValidatorData(creation: boolean = false): ValidatorDatasetData {
        return {
            name: this.scm.content.name,
            subject: [
                {
                    name: this.name,
                    variable: this.variables.reduce(
                        (acc, variable) => {
                            if (variable.value !== undefined || !creation) {
                                acc[variable.name] = variable.value;
                            }
                            return acc;
                        },
                        {} as Record<string, string | undefined>
                    ),
                    dataset: this.datasets.map(dataset => {
                        return {
                            name: dataset.scm.content.name,
                            subject: dataset.subjects
                                .map(subject => subject.getValidatorData(creation)?.subject?.[0])
                                .filter(subject => !!subject)
                        };
                    })
                }
            ]
        };
    }

    public validate(creation: boolean = false): ScmEvaluation | null {
        const values: RootDatasetData = {
            data: this.getValidatorData(creation)
        };
        let validatedData: ScmEvaluation;
        try {
            validatedData = evaluateRootDataset(
                this.project.scm,
                values,
                this.project.validationRules,
                creation
            );
            console.log("Validated data", validatedData);
        } catch (error) {
            throw new Error("Failed to validate data: " + error);
        }
        if (!validatedData.rootSubjectDataset.subjects.length) {
            throw new Error("No subjects found in validated data");
        }
        this.updateSubjectValidation(validatedData.rootSubjectDataset.subjects[0]);
        return validatedData;
    }

    getValidationMessages(
        includeChildren = false,
        isInitialization = false,
        forSection?: string,
        forDataset?: SubjectDataset
    ): TranslationMessage[] {
        if (forDataset) {
            return forDataset.subjects.flatMap(subject =>
                subject.getValidationMessages(true, isInitialization)
            );
        }
        const subjectMessages = this._subjectVariables
            .filter(
                subjectVariable =>
                    !subjectVariable.valid &&
                    subjectVariable.visible &&
                    this._sectionsVisibility[subjectVariable.section || ""] &&
                    (!forSection || this._fieldBelongsToSection(subjectVariable, forSection)) &&
                    (!isInitialization || this._checkedOnInitialization(subjectVariable))
            )
            .flatMap(subjectVariable =>
                subjectVariable
                    .getValidations()
                    .map<TranslationMessage>(validationData => ({
                        severity: validationData.severity,
                        message: validationData.message,
                        messageVariables: validationData.messageVariables
                    }))
                    .filter(
                        translationMessage =>
                            (Object.keys(translationMessage.message).length ?? 0) > 0
                    )
            );

        const childrenMessages = includeChildren
            ? this._subjectDatasets
                  .flatMap(dataset => dataset.subjects)
                  .flatMap(subject =>
                      subject.getValidationMessages(true, isInitialization, forSection)
                  )
            : [];
        return [...subjectMessages, ...childrenMessages];
    }

    setVariableValue(variableName: string, value: string): void {
        const variable = this.getVariable(variableName);
        if (variable) {
            const variableIndex = this._subjectVariables.findIndex(
                value => value.name === variableName
            );
            const changedVariable = Object.create(variable);
            changedVariable.value = value;
            this._subjectVariables[variableIndex] = changedVariable;
            this._subjectVariablesLookup[variableName] = changedVariable;
        }
    }

    updateSubjectValidation(evaluation: SubjectEvaluation): void {
        const previouslyInvalid =
            this.validations?.errors.flatMap(error => error.attributes).filter(uniqueFilter) ?? [];
        const scm = this.project.scm;
        const entityLists = extractEntityLists(scm);
        const entityLookup = createEntitiesLookup(entityLists);
        const parts = this.url.split("/") ?? [];
        const projectName = this.project.name;
        const path =
            parts.length > 2
                ? parts
                      .splice(2, parts.length)
                      .map((val, i) =>
                          i % 2 == 0 ? [val, projectName, "silver_entitylist"].join("-") : val
                      )
                : [];
        const rootValues = this.getRootSubject().getValidatorData(false);
        const values = {
            data: rootValues
        };
        const expressionContext = {
            path,
            entityLookup,
            values,
            scope: QualityRuleScope.Entity
        };
        const fieldAvailability = this._computeFieldVisibility(expressionContext);
        this._validations = {
            ...evaluation,
            availability: fieldAvailability,
            fieldsOptionsAvailability: this._computeFieldOptionsVisibility(expressionContext),
            errors: evaluation.errors.filter((error, index, array) => {
                return error.attributes.every(attribute => fieldAvailability[attribute] == true);
            })
        };
        this._sectionsVisibility = this._computeSectionVisibility(expressionContext);
        const nowInvalid = evaluation.errors
            .flatMap(error => error.attributes)
            .filter(uniqueFilter);
        // ATTRIBUTES THAT ARE IN ONE OF THE FIELDS CHANGED
        const changedAttributes = previouslyInvalid
            .concat(nowInvalid)
            .filter((attribute, index, array) => {
                return array.filter(a => a === attribute).length == 1;
            });
        changedAttributes.forEach(attribute => {
            const variable = this.getVariable(attribute);
            if (variable) {
                variable.updateValidation();
            }
        });
        for (const childDataset of evaluation.datasets) {
            this._subjectDatasetsNameLookup[childDataset.name].updateDatasetValidation(
                childDataset
            );
        }
    }

    private _computeFieldVisibility(expressionContext: ExpressionContext): FieldsAvailability {
        const fieldAvailability: FieldsAvailability = {};
        this.formConfiguration?.attributes?.forEach(attribute => {
            if (attribute.condition) {
                fieldAvailability[attribute.name] =
                    ruleLogicalExpression(attribute.condition.expression, expressionContext) ??
                    true;
            } else {
                fieldAvailability[attribute.name] = true;
            }
        });
        return fieldAvailability;
    }

    private _computeSectionVisibility(expressionContext: ExpressionContext): FieldsAvailability {
        const sectionVisibility: FieldsAvailability = {};
        this.formConfiguration?.sections?.forEach(section => {
            if (section.condition) {
                sectionVisibility[section.name] =
                    ruleLogicalExpression(section.condition.expression, expressionContext) ?? true;
            } else {
                sectionVisibility[section.name] = true;
            }
            let visibleFound = false;
            section.categories.forEach(category => {
                category.fields.forEach(field => {
                    if (field.type === FormConfigurationSectionCategoryFieldType.Attribute) {
                        if (this.getVariable(field.refName)?.visible) {
                            visibleFound = true;
                        }
                    } else {
                        visibleFound = true;
                    }
                });
            });
            if (!visibleFound) {
                sectionVisibility[section.name] = false;
            }
        });
        return sectionVisibility;
    }

    private _computeFieldOptionsVisibility(
        expressionContext: ExpressionContext
    ): FieldsOptionsAvailability {
        const fieldOptionsAvailability: FieldsOptionsAvailability = {};
        this.formConfiguration?.attributes?.forEach(attribute => {
            if (attribute.optionConditions) {
                fieldOptionsAvailability[attribute.name] = {};
                Object.entries(attribute.optionConditions).forEach(([option, condition]) => {
                    fieldOptionsAvailability[attribute.name][option] =
                        ruleLogicalExpression(condition.expression, expressionContext) ?? true;
                });
            }
        });
        return fieldOptionsAvailability;
    }

    private _checkedOnInitialization(subjectVariable: SubjectVariable): boolean {
        return this.keyVariables
            .map(keyVariable => keyVariable.name)
            .includes(subjectVariable.name);
    }

    private _fieldBelongsToSection(subjectVariable: SubjectVariable, sectionName: string): boolean {
        return subjectVariable.section === sectionName;
    }

    private _processDatasets(datasetData: Record<string, DatasetData>): void {
        for (const datasetRelation of this.formConfiguration?.datasets ?? []) {
            const datasetScm = this.project.getDatasetScm(datasetRelation.dataset);
            if (!datasetScm) {
                console.error("FAILED TO FIND DATASET SCM", datasetRelation.dataset);
                continue;
            }
            const datasetFormConfiguration = this.project.getDatasetFormConfiguration(
                datasetRelation.dataset
            );
            const subjectDataset = new SubjectDataset(
                this.project,
                datasetScm,
                this,
                datasetData[datasetRelation.dataset] ?? {
                    name: datasetRelation.dataset,
                    subject: []
                },
                datasetFormConfiguration
            );
            this._subjectDatasets.push(subjectDataset);
            this._subjectDatasetsLookup[datasetRelation.dataset] = subjectDataset;
            this._subjectDatasetsNameLookup[subjectDataset.scm.content.name] = subjectDataset;
        }
    }

    private _processVariables(variablesData: Record<string, SubjectVariableData>): void {
        for (const attributeScm of this.scm.content.childContract.content.attributes ?? []) {
            const attributeFormConfiguration = this.formConfiguration?.attributes?.find(
                attribute => {
                    return attribute.name === attributeScm.name;
                }
            );
            const variable = SubjectVariable.create(
                attributeScm,
                this,
                variablesData[attributeScm.name] ?? {
                    name: attributeScm.name
                },
                this.scm.content.childContract.content.attachedExtension || [],
                attributeFormConfiguration
            );
            this._subjectVariables.push(variable);
            this._subjectVariablesLookup[attributeScm.name] = variable;
        }
    }
}
