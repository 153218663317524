import { FormConfigurationDataset } from "@fhir-api";
import { FhirEntity } from "./entity";
import { FhirEntityListGroup } from "./entity-list-group";
import { ScmEntityList } from "@logex/expression-validator";

export class FhirEntityList {
    get scm(): ScmEntityList {
        return this._scm;
    }

    get formConfiguration(): FormConfigurationDataset | undefined {
        return this._formConfiguration;
    }

    get entity(): FhirEntity {
        return this._entity;
    }

    get parentEntityListGroup(): FhirEntityListGroup {
        return this._parentEntityListGroup;
    }

    private _entity: FhirEntity;

    constructor(
        private _parentEntityListGroup: FhirEntityListGroup,
        private _scm: ScmEntityList,
        private _formConfiguration?: FormConfigurationDataset
    ) {
        this._entity = new FhirEntity(this, _scm.content.childContract, this._formConfiguration);
    }
}
