import { Injectable, inject } from "@angular/core";

import { INavNode } from "@logex/framework/lg-application";

import { AppProjectsService } from "../app-projects.service";
import { AppStateService } from "./app-state.service";

import { FhirDataset } from "@app/model";

@Injectable({
    providedIn: "root"
})
export class AppNavigationManagerService {
    private _appStateService = inject(AppStateService);
    private _projectsService = inject(AppProjectsService);

    init(): void {
        this._update(null);

        this._projectsService.filteredSelectedProject$.subscribe(project => {
            this._update(project);
        });
    }

    private _update(project?: FhirDataset | null): void {
        const appNavigation = this._getAppNavigation(project);
        this._appStateService.setNavigation(appNavigation);
    }

    private _getAppNavigation(project?: FhirDataset | null): INavNode[] {
        const projectsNavigation = this._getProjectsNavigation(project);
        return [
            {
                id: "root",
                path: "",
                noBreadcrumb: true,
                children: projectsNavigation
            }
        ];
    }

    private _getProjectsNavigation(project?: FhirDataset | null): INavNode[] {
        const projectsNavigation: INavNode[] = [this._getSelectProjectNavNode()];
        if (project) {
            projectsNavigation.push(this._getSelectedProjectNavNode(project));
        }
        return projectsNavigation;
    }

    private _getSelectProjectNavNode(): INavNode {
        return {
            id: "select",
            path: "select",
            lid: "APP._Navigation.Select_registry"
        };
    }

    private _getSelectedProjectNavNode(project: FhirDataset): INavNode {
        const selectProjectNav = {
            id: "list",
            path: project.url,
            lid: "APP._Navigation.Select_subject",
            children: [
                {
                    id: "subject",
                    name: "Edit",
                    path: "",
                    disabled: true,
                    noBreadcrumb: true
                }
            ]
        };

        return {
            path: "",
            name: project.name,
            children: [selectProjectNav]
        };
    }
}
