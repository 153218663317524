import { FormConfigurationAttributeInitializer, SubjectData } from "@fhir-api";

import { FHIRSubjectDataset } from "./subject-dataset";
import { FHIRSubjectVariable } from "./subject-variable";

import { FhirAttribute } from "./attribute";
import { FhirEntity } from "./entity";

import { SubjectEvaluation } from "@logex/expression-validator";
import { DatasetSection } from "./section";

interface FHIRSubjectConstructorOptions {
    parentDataset: FHIRSubjectDataset;
    parentSubject?: FHIRSubject | null;
}

export interface TranslationMessage {
    message: string;
    messageVariables?: Record<string, string | number>;
}

export class FHIRSubject {
    static create(data: SubjectData, options: FHIRSubjectConstructorOptions): FHIRSubject {
        return new FHIRSubject(data, options);
    }

    static createEmpty({
        parentDataset,
        parentSubject,
        keyVariables,
        defaultValues
    }: {
        parentDataset: FHIRSubjectDataset;
        parentSubject?: FHIRSubject | null;
        keyVariables?: FhirAttribute[];
        defaultValues?: Record<string, string | null>;
    }): FHIRSubject {
        const data = {
            name: "$new",
            variable: (keyVariables ?? []).map(variable => {
                const value = defaultValues?.[variable.name] ?? null;
                return {
                    name: variable.name,
                    value: value === null ? "" : value
                };
            })
        };

        return FHIRSubject.create(data, { parentDataset, parentSubject });
    }

    static createUrlFromApiPath(
        projectUrl: string,
        datasetApiPath: string,
        subjectApiPath: string
    ): string {
        const datasetNames = datasetApiPath.split(".").slice(1);
        const subjectNames = subjectApiPath.split(".");

        let url = `${projectUrl}/subject`;

        for (let i = 0; i < subjectNames.length; i++) {
            url += `/${subjectNames[i]}${datasetNames[i] ? `/${datasetNames[i]}` : ""}`;
        }

        return url;
    }

    get validations(): SubjectEvaluation | null {
        return this._validations ?? null;
    }

    get name(): string {
        return this._data.name;
    }

    get label(): string {
        const keyVariables = this.entity.keyVariables;
        const label = keyVariables
            .map(variable => {
                const subjectVariable = this.getVariable(variable.name);
                return variable.getValueLabel(subjectVariable?.value);
            })
            .join("/");
        return label.length > 0 ? label : "-";
    }

    get url(): string {
        return this._url;
    }

    get apiPath(): string {
        return this._apiPath;
    }

    get urlSegments(): string[] {
        return [this._parentDataset.name, this._data.name];
    }

    get canDelete(): boolean {
        return true;
    }

    get parentDataset(): FHIRSubjectDataset {
        return this._parentDataset;
    }

    get datasets(): FHIRSubjectDataset[] {
        return this._subjectDatasets;
    }

    get parentSubject(): FHIRSubject | null {
        return this._parentSubject;
    }

    get variables(): FHIRSubjectVariable[] {
        return this._subjectVariables;
    }

    get entity(): FhirEntity {
        return this._parentDataset.entity;
    }

    get visible(): boolean {
        for (const variable of this._subjectVariables) {
            if (variable.visible) {
                return true;
            }
        }
        return false;
    }

    get valid(): boolean {
        return this.getValidationMessages(true).length === 0;
    }

    private _url = "";
    private _apiPath = "";
    private _parentDataset: FHIRSubjectDataset;
    private _parentSubject: FHIRSubject | null = null;
    private _subjectDatasets: FHIRSubjectDataset[] = [];
    private _subjectDatasetsLookup: Record<string, FHIRSubjectDataset> = {};
    private _subjectDatasetSections: DatasetSection[] = [];
    private _subjectDatasetsSectionsLookup: Record<string, DatasetSection> = {};
    private _subjectVariables: FHIRSubjectVariable[] = [];
    private _subjectVariablesLookup: Record<string, FHIRSubjectVariable> = {};
    private _validations: SubjectEvaluation | null = null;

    constructor(
        private _data: SubjectData,
        { parentDataset, parentSubject }: FHIRSubjectConstructorOptions
    ) {
        if (parentDataset) {
            this._url = `${parentDataset.url}`;
        }

        if (!parentSubject) {
            // root subject
            this._url += `/subject/${this.name}`;
        } else {
            this._url += `/${this.name}`;
        }

        this._parentDataset = parentDataset;
        this._parentSubject = parentSubject ?? null;

        if (this._parentSubject) {
            this._apiPath = `${this._parentSubject.apiPath}.${this.name}`;
        } else {
            this._apiPath = this.name;
        }

        this._proccesSections();
        this._processDatasets();
        this._processVariables();
    }

    getClone(): FHIRSubject {
        return FHIRSubject.create(this._data, {
            parentDataset: this._parentDataset
        });
    }

    getSection(name: string) {
        return this._subjectDatasetsSectionsLookup[name] ?? this;
    }

    getSections(): DatasetSection[] {
        return this._subjectDatasetSections;
    }

    getDataset(name: string): FHIRSubjectDataset | null {
        return this._subjectDatasetsLookup[name] ?? null;
    }

    getRootDataset(): FHIRSubjectDataset {
        let root = this._parentDataset;
        while (root.parentDataset) {
            root = root.parentDataset;
        }
        return root;
    }

    getRootSubject(): FHIRSubject {
        let root = this._parentSubject;
        while (root?.parentSubject) {
            root = root.parentSubject;
        }
        return root ?? this;
    }

    getVariable(variableName: string): FHIRSubjectVariable | null {
        return this._subjectVariablesLookup[variableName] ?? null;
    }

    getValidationMessages(includeChildren = false, sectionName?: string): TranslationMessage[] {
        const subjectMessages = this._subjectVariables
            .filter(subjectVariable => {
                const variable = this._parentDataset.entity.getVariable(subjectVariable.name);
                let allowedVariables: string[] = this._subjectVariables.map(
                    variable => variable.name
                );
                let section = this.entity.sections.find(section => section.name === sectionName);
                if (section) {
                    allowedVariables = section.allAttributes;
                }
                return (
                    subjectVariable.visible &&
                    !subjectVariable.valid &&
                    allowedVariables.includes(subjectVariable.name)
                );
            })
            .flatMap(subjectVariable => {
                const variable = this._parentDataset.entity.getVariable(subjectVariable.name);
                return (
                    (variable?.getValidations(subjectVariable) ?? [])
                        .map(
                            validationData =>
                                ({
                                    message: validationData.message,
                                    messageVariables: validationData.messageVariables
                                }) as TranslationMessage
                        )
                        .filter(
                            translationMessage =>
                                translationMessage.message != null &&
                                translationMessage.message.trim().length > 0
                        ) ?? []
                );
            });

        const childrenMessages = includeChildren
            ? this._subjectDatasets.flatMap(dataset =>
                  dataset.subjects.flatMap(subject =>
                      subject.getValidationMessages(includeChildren)
                  )
              )
            : [];
        return [...subjectMessages, ...childrenMessages];
    }

    setVariableValue(variableName: string, value: string): void {
        const variable = this.getVariable(variableName);
        if (variable) {
            variable.value = value;
        }
    }

    updateSubjectValidation(evaluation: SubjectEvaluation): void {
        this._validations = evaluation;
        for (const childDataset of evaluation.datasets) {
            this._subjectDatasetsLookup[childDataset.name].updateDatasetValidation(childDataset);
        }
    }

    private _proccesSections(): void {
        for (const section of this.entity.sections) {
            const subjectSection = section.setSubject(this);
            this._subjectDatasetSections.push(subjectSection);
            this._subjectDatasetsSectionsLookup[subjectSection.name] = subjectSection;
        }
    }

    private _processDatasets(): void {
        const root = this._parentDataset.dataset;
        for (const datasetData of this._data.dataset ?? []) {
            const entityList = root.getDataset(datasetData.name);
            if (!entityList) {
                // TODO check if it can happen and what to do?
                continue;
            }
            const dataEntryDataset = new FHIRSubjectDataset(datasetData, entityList, {
                parentSubject: this,
                parentDataset: this._parentDataset
            });
            this._subjectDatasets.push(dataEntryDataset);
            this._subjectDatasetsLookup[datasetData.name] = dataEntryDataset;
            const datasetSection = new DatasetSection(
                "dataset",
                this.entity,
                undefined,
                dataEntryDataset
            );
            this._subjectDatasetSections.push(datasetSection);
            this._subjectDatasetsSectionsLookup[datasetSection.name] = datasetSection;
        }
    }

    private _processVariables(): void {
        for (const attribute of this.entity.attributes ?? []) {
            const defaultValue = attribute.defaultValue;
            let value;

            if (defaultValue) {
                if (defaultValue.hasOwnProperty("type")) {
                    value = attribute.runInitializer(
                        defaultValue as FormConfigurationAttributeInitializer,
                        this.getRootSubject()
                    );
                } else {
                    value = defaultValue as string;
                }
            }

            let variableData = this._data.variable?.find(({ name }) => name === attribute.name) ?? {
                name: attribute.name,
                value: value,
                type: attribute.scm.dataType
            };
            const variable = FHIRSubjectVariable.create(variableData, attribute, {
                parentSubject: this
            });
            this._subjectVariables.push(variable);
            this._subjectVariablesLookup[variable.name] = variable;
        }
    }
}
