export interface FormConfigurationLocalizedTextDefinition {
    content?: string;
    language?: string;
}

export const enum FormConfigurationOptionsDirection {
    Vertical = "vertical",
    Horizontal = "horizontal"
}

export interface FormConfigurationOptionDefinition {
    value: string;
    label: FormConfigurationLocalizedTextDefinition[];
    description?: FormConfigurationLocalizedTextDefinition[];
}

export const enum DataEntryUIControlType {
    Radio = "radiobutton",
    ImageRadio = "image-radio",
    Checkbox = "checkbox",
    Select = "dropdown",
    Autocomplete = "autocomplete",
    Textarea = "textarea",
    Text = "text",
    Date = "calendar",
    DateTime = "datetime",
    Integer = "integer",
    Float = "float",
    Dataset = "dataset",
    Paragraph = "paragraph",
    ExternalDatasetCount = "external-dataset-count",
    ExternalDataset = "external-dataset",
    CreateButton = "create-button"
}

export interface FormConfigurationAttribute {
    name: string;
    label?: string;
    inputControl?: DataEntryUIControlType;
    readonly?: boolean;
    include?: boolean;
    optionsDirection?: FormConfigurationOptionsDirection;
    help?: string;
    options?: FormConfigurationOptionDefinition[];
    defaultValue?: string; // Might actually be more types, but from data I have doesn't seem so
}

export const enum FormConfigurationEntityExtraFieldType {
    Setter = "setter",
    Paragraph = "paragraph"
}

export interface FormConfigurationEntityExtraFieldPositionAbsolute {
    type: "first" | "last";
}

export interface FormConfigurationEntityExtraFieldPositionRelative {
    type: "before" | "after";
    referenceItemName: string;
}

export type FormConfigurationEntityExtraFieldPosition =
    | FormConfigurationEntityExtraFieldPositionAbsolute
    | FormConfigurationEntityExtraFieldPositionRelative;

export interface FormConfigurationEntityExtraFieldBase {
    name: string;
    type: FormConfigurationEntityExtraFieldType;
    label?: string;
    labelVariables?: Record<string, string>;
    position?: FormConfigurationEntityExtraFieldPosition;
    standalone?: boolean;
}

export interface FormConfigurationEntityExtraFieldSetterOption {
    value: string;
    label: string;
}

export interface FormConfigurationEntityExtraFieldSetter
    extends FormConfigurationEntityExtraFieldBase {
    type: FormConfigurationEntityExtraFieldType.Setter;
    attributesToSet: string[];
    options: FormConfigurationEntityExtraFieldSetterOption[];
}

export interface FormConfigurationEntityExtraFieldParagraph
    extends FormConfigurationEntityExtraFieldBase {
    type: FormConfigurationEntityExtraFieldType.Paragraph;
}

export type FormConfigurationEntityExtraField =
    | FormConfigurationEntityExtraFieldSetter
    | FormConfigurationEntityExtraFieldParagraph;

export enum FormConfigurationCreateFromExistingPickerType {
    LatestDate = "latestDate"
}

export interface FormConfigurationCreateFromExistingSettings {
    label?: string;
    excludeKeys?: string[];
    pickerType?: FormConfigurationCreateFromExistingPickerType;
}

export interface FormConfigurationSettings {
    createFromExisting?: FormConfigurationCreateFromExistingSettings;
}

export interface FormConfigurationSectionCategory {
    name: string;
    label: string;
    attributes: string[];
}

export interface FormConfigurationSection {
    name: string;
    label?: string;
    categories?: FormConfigurationSectionCategory[];
    attributes: string[];
}

export interface FormConfigurationDatasetParagraph {
    name: string;
    position: "start" | "end";
    label: string;
}

export interface FormConfigurationDatasetList {
    label?: string;
    paragraphs?: FormConfigurationDatasetParagraph[];
}
export interface FormConfigurationDataset {
    contractId: string;
    businessAlias: string;
    settings?: FormConfigurationSettings;
    label?: string;
    keyedVariables: string[];
    extraFields?: FormConfigurationEntityExtraField[];
    sections?: FormConfigurationSection[];
    attributes?: FormConfigurationAttribute[];
    datasetList?: FormConfigurationDatasetList;
}

export interface FormConfiguration {
    author: string;
    businessId: string;
    datasets: FormConfigurationDataset[];
    contractId?: string;
    maintainer?: string;
    name: string;
    uuid?: string;
    version?: string;
}

export type GetFormConfigurationResponseData = [FormConfiguration];
