import { AppProjectsService } from "@app/services/app-projects.service";
import { DataEntryUIControlType, FormConfiguration, FormConfigurationDataset } from "@fhir-api";
import { Scm, ScmEntityList } from "@logex/expression-validator";
import { SubjectDataset } from "./subject-dataset";
import { QualityRule } from "@logex/expression-validator/src/lib/types/quality-rule.type";

interface DatasetConstructorOptions {
    organizationUri?: string;
    organizationId?: number;
}

export class Project {
    private _product: string | null = null;
    private _url = "";

    get name(): string {
        return this._name;
    }

    get label(): string {
        return this._formConfiguration?.label ?? this._formConfiguration?.name ?? this._scm.name;
    }

    get description(): string {
        return this._scm?.description ?? "";
    }

    get defaultLanguage(): string | null {
        return null;
    }

    get rootDataset(): SubjectDataset {
        return this._rootDataset;
    }

    get url(): string {
        return this._url;
    }

    get defaultControl(): DataEntryUIControlType {
        return DataEntryUIControlType.Text;
    }

    get skipEmptyCreationDialog(): boolean {
        return false;
    }

    get hideSubjectCountInSubjectList(): boolean {
        return false;
    }

    get scm(): Scm {
        return this._scm;
    }

    get validationRules(): QualityRule[] {
        return this._validationRules;
    }

    get formConfiguration(): FormConfiguration | undefined {
        return this._formConfiguration;
    }

    get organizationId(): string | null {
        return this._organizationId;
    }

    private _rootDataset!: SubjectDataset;
    private _organizationId: string | null = null;
    private _datasetScmLookup: Record<string, ScmEntityList> = {};
    private _datasetFormConfigurationLookup: Record<string, FormConfigurationDataset> = {};

    constructor(
        private _name: string,
        private _rootDatasetName: string,
        private _projectsService: AppProjectsService,
        private _scm: Scm,
        private _formConfiguration?: FormConfiguration,
        private _validationRules: QualityRule[] = [],
        { organizationUri, organizationId }: DatasetConstructorOptions = {}
    ) {
        this._initializeLookups();
        this._setProduct();
        this._initializeRootDataset();

        if (organizationUri !== undefined) {
            this._organizationId = organizationId?.toString() ?? null;
            this._url = `/registry/${organizationUri}/${this.name}`;
        }
    }

    getProduct(): string | null {
        return this._product;
    }

    getDatasetScm(name: string): ScmEntityList | undefined {
        return this._datasetScmLookup[name];
    }

    getDatasetFormConfiguration(name: string): FormConfigurationDataset | undefined {
        return this._formConfiguration?.datasets.find(dataset => dataset.name === name);
    }

    private _initializeRootDataset(): void {
        const rootDatasetScm = this.getDatasetScm(this._rootDatasetName);
        if (!rootDatasetScm) {
            throw new Error(
                `Root dataset ${this._rootDatasetName} not found in project ${this.name}`
            );
        }
        this._rootDataset = new SubjectDataset(
            this,
            rootDatasetScm,
            null,
            {
                name: this._rootDatasetName,
                subject: []
            },
            this.getDatasetFormConfiguration(this._rootDatasetName)
        );
    }

    private _initializeLookups(): void {
        this._scm.childContract
            .flatMap(entityListGroup => {
                return entityListGroup.content.childContract;
            })
            .forEach(entityList => {
                this._datasetScmLookup[entityList.businessAlias] = entityList;
            });
        this._formConfiguration?.datasets.forEach(dataset => {
            this._datasetFormConfigurationLookup[dataset.name] = dataset;
        });
    }

    private _setProduct(): void {
        const project = this._projectsService.getProjectByUri(this.name);
        if (project?.properties.product === undefined) return;

        this._product = project.properties.product.replace("Product.", "");
    }
}
