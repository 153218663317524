import { inject, Injectable } from "@angular/core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgPromptDialog } from "@logex/framework/ui-core";

@Injectable({ providedIn: "root" })
export class AppDialogService {
    private _confirmationDialog = inject(LgPromptDialog);
    private _translateService = inject(LgTranslateService);

    public async displayErrorMessage(message: string, action?: () => void): Promise<string> {
        const title = this._translateService.translate("APP._Error.Title");

        const value = await this._confirmationDialog.alert(title, message);
        if (action) {
            action();
        }

        return value;
    }

    public displayMissingBodyErrorMessage(): Promise<string> {
        return this.displayErrorMessage("Response has been received without body.");
    }

    public displaySaveInProgressMessage(): Promise<string> {
        return this._confirmationDialog.alertLc(
            "APP._SubjectEdit.Save_in_progress_title",
            "APP._SubjectEdit.Save_in_progress_text"
        );
    }

    public displayLockedSubjectMessage(): Promise<string> {
        return this._confirmationDialog.confirmLc(
            "APP._SubjectEdit.Subject_locked_title",
            "APP._SubjectEdit.Subject_locked_text",
            {
                dialogType: "alert",
                buttons: [
                    {
                        id: "leave",
                        nameLc: this._translateService.translate(
                            "APP._SubjectEdit.Subject_locked_leave"
                        )
                    },
                    {
                        id: "view",
                        nameLc: this._translateService.translate(
                            "APP._SubjectEdit.Subject_locked_view"
                        ),
                        isCancelAction: true
                    }
                ],
                closeOnOverlayClick: true
            }
        );
    }

    public displayCannotProceedMessage(variablesInfo: string | undefined): Promise<string> {
        return this._confirmationDialog.alert(
            this._translateService.translate("APP._SubjectEdit._CannotProceedDialog.Title"),
            this._translateService.translate("APP._SubjectEdit._CannotProceedDialog.Text", {
                variables: variablesInfo
            })
        );
    }

    public displayNoAccessErrorMessage(): Promise<string> {
        return this._confirmationDialog.alert(
            this._translateService.translate("APP._Error.Title"),
            this._translateService.translate("APP._SelectProject.No_access_error")
        );
    }
}
